.followup_clockColor_1{
  color:#202020;/*green-500*/
}
.followup_clockColor_2{
  color:#eab308; /*yellow-500*/
}
.followup_clockColor_3{
  color:#ef4444; /*red-500*/}

.followup_clockColor_4{
  color:#f77502; /*red-500*/}