.loader {
  width: 120px;
  height: 20px;
  border:"2px solid #fff";
  margin-bottom: 5px;
  background: 
    linear-gradient(90deg,#ffffff04 33%,#00000011 50%,#ffffff04 66%)
    #fafafa;
  background-size:300% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {background-position: right}
}

.unverified {
  width: 12px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,orange 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 2s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}
.flagColor_1{
  color:#22c55e;/*green-500*/
}
.flagColor_2{
  color:#eab308; /*yellow-500*/
}
.flagColor_3{
  color:#ef4444; /*red-500*/
}