.notes {
    padding: 2rem 0;
}

.notes ul {
    list-style-type: disc;
    padding-left:1rem;
    padding-top:1rem;
    padding-bottom:1rem;
    text-align: left;
}

.notes ol {
    list-style-type: decimal;
    padding-left:1rem;
    padding-top:1rem;
    padding-bottom:1rem;
}

.tox-tinymce {
    width: 100%;
}

.notes a {
    text-decoration: underline;
    color: #9333EA;
}
