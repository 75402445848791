body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body ::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  border-radius: 15px;
  background-color: #F5F5F5;
  width:5px;
  height: 5px;
}

body ::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
  width:5px;
  height: 5px;
}

body ::-webkit-scrollbar-thumb
{
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6b21a8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#rc-widget {
  z-index: 2147483650 !important;
  position: fixed;
}

@tailwind base;
@tailwind components;
@tailwind utilities;









