:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;
  --fc-transparent-color:#ffffff00;

  --fc-button-text-color: #fff;
  /* 9333EA */
  /* 6b21a8 */
  --fc-button-bg-color: #9333EA;
  --fc-button-border-color: #9333EA;
  --fc-button-hover-bg-color: rgb(59 7 100);
  --fc-button-hover-border-color: #6b21a8;
  --fc-button-active-bg-color: #6b21a8;
  --fc-button-active-border-color: rgb(59 7 100);

  --fc-event-bg-color: #D8B4FE;
  --fc-event-border-color: #D8B4FE;
  --fc-event-text-color: #rgb(59 7 100);
  --fc-event-selected-overlay-color:#D8B4FE;

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}

.fc-event {
  overflow: hidden;  
}

@media screen and (max-width: 768px) {
  .fc-header-toolbar {
    flex-direction: column-reverse;
  }
}

